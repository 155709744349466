const ToggleButton = ({ leftText, rightText, selectedOption, toggleOption }) => {
    return (
        <div className="flex items-center bg-gray-300 rounded-full">
            <button
                onClick={() => toggleOption("outstanding")}
                className={`px-4 py-2 rounded-[1rem] text-center ${selectedOption === "outstanding"
                    ? "bg-gray-500 text-white text-lg"
                    : "bg-gray-300 text-gray-800 text-lg"
                    }`}
            >
                {leftText}
            </button>
            <button
                onClick={() => toggleOption("solved")}
                className={`px-4 py-2 rounded-[1rem] ${selectedOption === "solved"
                    ? "bg-gray-500 text-white text-lg"
                    : "bg-gray-300 text-gray-800 text-lg"
                    }`}
            >
                {rightText}
            </button>
        </div>
    );
}

export default ToggleButton;
