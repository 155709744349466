import React from "react";

const LoaderIcon = () => {
    return(
        <div className="flex space-x-2 animate-pulse">
            {
                [1,2,3].map(item => (
                    <div className={`w-3 h-3 bg-gray-500 rounded-full`} key={item}></div>
                ))
            }
        </div>
    )
}

export default LoaderIcon