import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosInstance'

const Login = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });

    const [errors, setErrors] = useState({
        username: '',
        password: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setErrors({
            ...errors,
            [name]: '', // Clear the error when the user starts typing again
        });
    };

    const validateForm = () => {
        let valid = true;
        const newErrors = {};

        // username validation
        if (!formData.username.trim()) {
            newErrors.username = 'username is required';
            valid = false;
        }

        // Password validation
        if (!formData.password.trim()) {
            newErrors.password = 'Password is required';
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            try {
                const response = await axiosInstance.post(`/users/login`, formData);
                const { token } = response.data;

                // Save the token to localStorage
                localStorage.setItem('token', token);

                navigate('/chat');
            } catch (error) {
                console.error('Login failed:', error.response?.data.message || 'Unknown error');
                setErrors({ password: error.response?.data.message || 'Unknown error' })
            }
        }
    };
    return (
        <div className="h-screen flex">
            <div className="flex w-full lg:w-1/2 justify-center items-center bg-white space-y-8 m-auto">
                <div className="w-full px-8 md:px-32 lg:px-24">
                    <form className="bg-white rounded-md shadow-2xl p-5">
                        <h1 className="text-gray-800 font-bold text-2xl mb-3">OmnidexAI</h1>
                        <div className={`flex items-center border-2 mb-2 py-2 px-3 rounded-2xl ${errors.username && 'border-red-500'}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                            </svg>
                            <input
                                id="username"
                                className="pl-2 w-full outline-none border-none"
                                style={{ boxShadow: 'none' }}
                                type="text"
                                name="username"
                                placeholder="Username"
                                value={formData.username}
                                onChange={handleChange}
                            />
                        </div>
                        {errors.username && <p className="text-red-500 text-xs mb-4">{errors.username}</p>}
                        <div className={`flex items-center border-2 mb-2 py-2 px-3 rounded-2xl ${errors.password && 'border-red-500'}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                            </svg>
                            <input
                                className="pl-2 w-full outline-none border-none"
                                type="password"
                                name="password"
                                style={{ boxShadow: 'none' }}
                                id="password"
                                placeholder="Password"
                                value={formData.password}
                                onChange={handleChange}
                            />
                        </div>
                        {errors.password && <p className="text-red-500 text-xs mb-4">{errors.password}</p>}
                        <button
                            type="button"
                            className="block w-full bg-indigo-600 mt-5 py-2 rounded-2xl hover:bg-indigo-700 hover:-translate-y-1 transition-all duration-500 text-white font-semibold mb-2"
                            onClick={handleSubmit}
                        >
                            Login
                        </button>
                    </form>
                </div>

            </div>
        </div>
    );
};

export default Login;
