import React, { useEffect, useState } from "react";
import Header from "./Header";
import axiosInstance from "../axiosInstance";
import BarGraph from "./BarGraph";
import LoaderIcon from "./LoaderIcon";
import { DateStringFormat, formattedDate } from "../helpers/helpers";

const Analytics = () => {
  const [isLoading, setLoading] = useState(false);
  const [trendingTopics, setTrendingTopics] = useState([]);
  const [conversationData, setConversationData] = useState(null);

  const [value] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 9)),
    endDate: new Date(),
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const [graphResponse, trendingResponse] = await Promise.all([
        axiosInstance.get(
          `/analytics/conversations-count?startDate=${formattedDate(
            value.startDate
          )}&endDate=${formattedDate(value.endDate)}`
        ),
        axiosInstance.get(
          `/analytics/trending?date=${formattedDate(new Date(
            new Date()
          ))}`
        ),
      ]);
      setLoading(false);
      if (graphResponse) {
        generateDateArray(
          value.startDate,
          value.endDate,
          graphResponse.data.data
        );
      }
      if (trendingResponse) {
        setTrendingTopics(trendingResponse.data.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const generateDateArray = (startDate, endDate, data) => {
    const dateArray = {
      label: [],
      datasets: [],
    };
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      dateArray.label.push(new Date(currentDate));
      if (data.length) {
        let dateData = data.filter(
          (item) => item.date.substring(0, 10) === formattedDate(currentDate)
        );
        dateArray.datasets.push(dateData.length ? dateData[0].count : null);
      } else {
        dateArray.datasets.push(null);
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    setConversationData(dateArray);
  };

  useEffect(() => {
    fetchData();
  }, [value]);

  return (
    <>
      <Header />
      <div className="container mx-auto shadow-lg rounded-lg">
        {isLoading ? (
          <div className="flex items-center justify-center min-h-96 p-5 min-w-screen">
            <LoaderIcon />
          </div>
        ) : (
          <div className="bg-white py-20">
            {conversationData && (
              <BarGraph
                title="Conversation Par Jour"
                graphData={conversationData}
              />
            )}
            <div className="w-3/4 mx-auto">
              <p className="font-bold text-2xl">
                {DateStringFormat(new Date())}
              </p>
              <div className="flex flex-col text-xl font-semibold pt-6 gap-3">
                <h1>Les sujets tendance d'aujourd'hui</h1>
                {trendingTopics && trendingTopics.length ? (
                  trendingTopics.map((item, index) => (
                    <p className="" key={index}>
                      {" "}
                      {` ${index + 1} - ${item.word}`}{" "}
                    </p>
                  ))
                ) : (
                  <p>No record Found!</p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Analytics;
