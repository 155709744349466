import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { PiFlagPennantFill } from "react-icons/pi";

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;

    const logout = () => {
        localStorage.clear();
        navigate("/");
    };
    return (
        <header>
            <nav className="bg-white border-gray-200 px-4 lg:px-6 py-2.5 border-b-2">
                <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
                    <div className="flex items-center">
                        <img
                            src="./OmnidexAI_logo.png"
                            className="mr-3 h-6 sm:h-9 cursor-pointer"
                            alt="Omnidex-AI Logo"
                            onClick={() => navigate('/chat')}
                        />
                    </div>
                    <div className="flex items-center cursor-pointer text-lg">
                        <p className="flex items-center">{pathname === "/admin" ? (
                            <>
                                <PiFlagPennantFill className="inline mr-1" size={35} style={{ color: 'red' }} />
                                <span className="font-bold text-2xl">Flagged</span>
                            </>) : <span onClick={() => navigate('/analytics')}>Statistiques</span>}</p>
                    </div>
                    <div className="flex items-center lg:order-2">
                        <button
                            className="block w-full select-none rounded-lg bg-white border-2 border-black py-2 px-6 text-center align-middle font-sans text-sm md:text-lg font-bold text-black shadow-md transition-all hover:shadow-lg active:opacity-85 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                            type="button"
                            data-ripple-light="true"
                            onClick={() => logout()}
                        >
                            log out
                        </button>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;
