import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const PrivateRoute = ({ component: Component }) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const navigate = useNavigate()

  useEffect(() => {
    const token = localStorage.getItem("token")
    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  return (
    <>
      {isAuthenticated === true ? <Component /> : navigate('/') }
    </>
  );
};
export default PrivateRoute;