import React, { useEffect, useState } from "react";
import Header from "./Header";
import appointments from "../json-data/appointmentData.json";
import { dayMonthOnlyDate } from "../helpers/helpers";

const Vaudreuil = () => {
  const [appointmentDate, setAppointmentDate] = useState();

  const fetchAppointmentDate = () => {
    const dates = appointments.dates;
    const today = new Date();
    let nextApptDate = null;
    for (const date of dates) {
      const [day, month, year] = date.split("-");
      const apptDate = new Date(`${year}-${month}-${day}`);
      if (apptDate > today) {
        nextApptDate = apptDate;
        break;
      }
    }
    setAppointmentDate(dayMonthOnlyDate(nextApptDate));
  };

  useEffect(() => {
    fetchAppointmentDate();
  });

  return (
    <>
      <Header />
      <div className="container mx-auto shadow-lg rounded-lg mt-10">
        <div className="p-10 flex flex-col text-xl gap-2">
          <h1>Question:  prochaine séance du conseil?</h1>
          <p>Reponse:</p>
          <p>La prochaine séance du conseil municipal est le <span className="text-2xl font-bold">{appointmentDate}</span> à 19h.</p>
          <p>Le conseil municipal de la Ville de Vaudreuil-Dorion siège habituellement deux fois par mois, soit le premier et le troisième lundi du mois, à 19 h, sauf l'été, où il se réunit une fois par mois. Pour plus d'information, visitez la page: [séances publiques] (https://www.ville.vaudreuil-dorion.qc.ca/fr/la-ville/mairie/seances-publiques)
          </p>
        </div>
      </div>
    </>
  );
};

export default Vaudreuil;
