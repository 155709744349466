import React, { useEffect } from "react";
import Chart from "chart.js/auto";

const BarGraph = ({ graphData, title }) => {
  const generateGraph = () => {
    const ctx = document.getElementById("analytics-chart").getContext("2d");
    if (ctx) {
      const existingChart = Chart.getChart(ctx);
      if (existingChart) {
        existingChart.destroy();
      }
      new Chart(ctx, {
        type: "bar",
        data: {
          labels: graphData.label.map((date) =>
            date.toLocaleDateString("fr", {
              day: "numeric",
              month: "short",
            })
          ),
          datasets: [
            {
              label: title,
              data: graphData.datasets,
              barThickness: 25,
              backgroundColor: "#435DB4",
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: true,
              text: title,
              font: {
                size: "24px",
                weight: "normal",
              },
              color: "#5D5A5A",
            },
          },
        },
      });
    }
  };

  useEffect(() => {
    generateGraph();
  }, [graphData]);

  return (
    <>
      <div
        className="mx-auto mb-14 border border-slate-400 sm:w-3/4 w-full p-6"
        style={{ minHeight: "200px", maxHeight: "600px" }}
      >
        <canvas id="analytics-chart"></canvas>
      </div>
    </>
  );
};

export default BarGraph;
